export interface TaxMappingEntity{

    id?: number | null,
    priority?: number | null,
    tempPriority?: number | null,
    billToCompanyCode?: string | null,
    chargeType?: string | null,
    subChargeType?: string | null,
    consortiumCode?: string | null,
    serviceCode?: string | null,
    chargeTariffType?: string | null,
    chargeTariffCode?: string | null,
    chargeTariffCodeDesc?: string | null,
    tariffType?: string | null,
    tariffCode?: string | null,
    vatTariffCodeDesc?: string | null,
    taxCode?: string | null,
    statementCode?: string | null,
    country?: string | null,
    docType?: string | null,
    paymentReferenceDate?: string | null,
    paymentTerm?: number | null,
    paymentReferenceDateCredit?: string | null,
    paymentTermCredit?: number | null,
    paymentReferenceDateDebit?: string | null,
    paymentTermDebit?: number | null,
    percentage?: number | null,
    vatTransactionType?: string | null,
    vatArticleStatement?: string | null,
    effectiveDate?: Date | null,
    region?: string | null,
    groupId?: string | null,
    activeInd: string | null,
    valueAdjType: string,
    mappingType: string,

    [key: string]: string | boolean | Date | number | null | Object | undefined
}

export const EMPTY_TAX_MAPPING_ENTITY : TaxMappingEntity = {

    id: 0,
    priority: null,
    tempPriority: null,
    billToCompanyCode: '',
    chargeType: '',
    subChargeType: '',
    consortiumCode: '',
    serviceCode: '',
    chargeTariffType: '',
    chargeTariffCode: '',
    chargeTariffCodeDesc: '',
    tariffType: '',
    tariffCode: '',
    vatTariffCodeDesc: '',
    taxCode: '',
    statementCode: '',
    country: '',
    docType: '',
    paymentReferenceDate: '',
    paymentTerm: null,
    paymentReferenceDateCredit: '',
    paymentTermCredit: null,
    paymentReferenceDateDebit: '',
    paymentTermDebit: null,
    percentage: null,
    vatTransactionType: '',
    vatArticleStatement: '',
    effectiveDate: null,
    region: '',
    groupId: '',
    valueAdjType: 'VAT',
    activeInd: 'Y',
    mappingType: "DocumentValueAdjEntity",

}
