import { ConsortiumRepoImpl } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TaxMappingRepoImpl } from "domain/repository/TaxMapping/TaxMappingRepoImpl";
import { useTaxMappingTracked } from "presentation/store/TaxMapping/TaxMappingProvider";
import { TaxMappingVM } from "presentation/viewModel/TaxMapping/TaxMappingVM";
import { useMemo } from "react";

export const useTaxMappingVM = () => {
    const [, setTaxMappingState] = useTaxMappingTracked();
    const taxMappingVM = useMemo(() =>
        TaxMappingVM({
            dispatch: [setTaxMappingState],
            taxMappingRepo: TaxMappingRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            consortiumRepo: ConsortiumRepoImpl(),
        }), [setTaxMappingState])

    return taxMappingVM
}