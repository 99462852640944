import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { TaxMappingConstant } from "./TaxMappingConstant";

const TAX_MAPPING_CONSTANT = TaxMappingConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_TAX_MAPPING_COL_DEF: any[] = [

    {
        headerName: TAX_MAPPING_CONSTANT.PRIORITY,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        },
        {
            headerName: TAX_MAPPING_CONSTANT.BILL_TO_COMPANY,
            field: "billToCompanyCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.CHARGE_TYPE,
            field: "chargeType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.SUB_CHARGE_TYPE,
            field: "subChargeType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.CONSORTIUM_CODE,
            field: "consortiumCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.SERVICE_CODE,
            field: "serviceCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.CHARGE_TAR_TYPE,
            field: "chargeTariffType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.CHARGE_TAR_CODE,
            field: "chargeTariffCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.CHARGE_TAR_CODE_DESC,
            field: "chargeTariffCodeDesc",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 230,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_TARIFF_TYPE,
            field: "tariffType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_TARIFF_CODE,
            field: "tariffCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_TARIFF_CODE_DESC,
            field: "vatTariffCodeDesc",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_CODE,
            field: "taxCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.ARTICLE_STATEMENT_CODE,
            field: "statementCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 230,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.COUNTRY,
            field: "country",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.DOC_TYPE,
            field: "docType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATEINVOICE,
            field: "paymentReferenceDate",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 350,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMINVOICE,
            field: "paymentTerm",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 260,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATECREDIT,
            field: "paymentReferenceDateCredit",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 350,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMCREDIT,
            field: "paymentTermCredit",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 260,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATEDEBIT,
            field: "paymentReferenceDateDebit",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 350,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMDEBIT,
            field: "paymentTermDebit",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 260,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TAX,
            field: "percentage",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.TRANSACTION_TYPE,
            field: "vatTransactionType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.ARTICLE_STATEMENT,
            field: "vatArticleStatement",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.EFFECTIVE_DATE,
            field: "effectiveDate",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            dataType : "date",
            },
            {
            headerName: TAX_MAPPING_CONSTANT.REGION,
            field: "region",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: TAX_MAPPING_CONSTANT.GROUP_ID,
            field: "groupId",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
        {
        headerName: TAX_MAPPING_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        },
        
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}